import { computed, type MaybeRef, toValue, type UnwrapRef } from 'vue'
import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'
import { CustomPropertyTypeEnum } from '@prisma/client'
import { formatDateLocale } from '../time'
import { useMetricValueFormatter } from '../numberFormat'
import { type EntityCoreTypeOrTypeId, isEntityCoreType } from '../entityType'
import { useI18n } from 'vue-i18n'
import { iconForCustomProperty } from '../customPropertyIcons'

export const useCustomProperties = (typeRef?: MaybeRef<EntityCoreTypeOrTypeId>) => {
    const { t } = useI18n()
    const { data, isFetching, isLoading, isRefetching, isPending } = useQuery({
        queryKey: ['listAllCustomProperties'],
        queryFn: () => client.customProperties.list.query(),
        initialData: [],
        queries: ['CustomProperty', 'CustomPropertyValue', 'CustomPropertyEnabledFor'],
    })

    const customProperties = computed(() => {
        const type = toValue(typeRef)

        return (
            data.value
                .filter((attr) => {
                    if (!type) return true
                    if (isEntityCoreType(type))
                        return attr.enabledFor.some(({ entityType }) => entityType.type === type)
                    return attr.enabledFor.some(({ entityType }) => entityType.id === type)
                })
                // map valueText in values to label
                .map(({ values, ...attr }) => {
                    let mappedValues = []

                    if (attr.type === CustomPropertyTypeEnum.checkbox) {
                        mappedValues = [
                            { label: t('yes'), value: true, id: 'true' },
                            { label: t('no'), value: false, id: 'false' },
                        ]
                    } else {
                        mappedValues = values
                            .map((i) => i)
                            .sort((a, b) => {
                                if (a.valueNumber && b.valueNumber)
                                    return b.valueNumber.toNumber() - a.valueNumber.toNumber()
                                if (a.valueDate && b.valueDate) return b.valueDate.getTime() - a.valueDate.getTime()
                                return 0
                            })
                            .map(({ valueText, valueDate, valueNumber, ...value }) => {
                                return {
                                    ...value,
                                    label:
                                        valueText ||
                                        (valueDate && formatDateLocale(valueDate)) ||
                                        (attr.type === 'currency' &&
                                            valueNumber &&
                                            useMetricValueFormatter(valueNumber.toNumber())) ||
                                        (attr.type === 'percentage' && valueNumber && `${valueNumber.toString()} %`) ||
                                        valueNumber?.toString() ||
                                        '',
                                    value: valueText || valueDate || valueNumber,
                                }
                            })
                    }

                    return {
                        ...attr,
                        values: mappedValues,
                        icon: iconForCustomProperty[attr.type],
                    }
                })
        )
    })

    return {
        customProperties,
        isFetching,
        isLoading,
        isRefetching,
        isPending,
    }
}

export type CustomPropertiesData = UnwrapRef<ReturnType<typeof useCustomProperties>['customProperties']>

import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'
import type { Person } from '@prisma/client'
import type { SelectorItem } from '../selector'
import { computed } from 'vue'

export type PersonType = Pick<Person, 'id' | 'auth0Id' | 'name' | 'position' | 'photoUrl' | 'email' | 'isActive'>

export const usePersons = () => {
    const {
        data: allPersons,
        isLoading: isLoadingAllPersons,
        isFetching: isFetchingAllPersons,
        isRefetching: isRefetchingAllPersons,
    } = useQuery({
        queryKey: ['listAllPersons'],
        queryFn: () => client.person.list.query(),
        queries: ['Person'],
        initialData: [],
    })
    const {
        data: nonExternalPersons,
        isLoading: isLoadingNonExternalPersons,
        isFetching: isFetchingNonExternalPersons,
        isRefetching: isRefetchingNonExternalPersons,
    } = useQuery({
        queryKey: ['listNonExternalPersons'],
        queryFn: () => client.person.listNonExternal.query(),
        queries: ['Person'],
        initialData: [],
    })
    const {
        data: allIncludingInactivePersons,
        isLoading: isLoadingAllIncludingInactivePersons,
        isFetching: isFetchingAllIncludingInactivePersons,
        isRefetching: isRefetchingAllIncludingInactivePersons,
    } = useQuery({
        queryKey: ['listAllIncludingInactivePersons'],
        queryFn: () => client.person.listAll.query(),
        queries: ['Person'],
        initialData: [],
    })

    const allIncludingInactivePersonsForSelector = computed(() =>
        allIncludingInactivePersons.value.map(
            (person) =>
                ({
                    id: person.id,
                    label: person.name,
                    email: person.email,
                    photoUrl: person.photoUrl,
                    isActive: person.isActive,
                }) satisfies SelectorItem
        )
    )

    function getAllPersonsIncludingInactive(persons?: PersonType[]) {
        const inactivePersons = persons?.filter(({ isActive }) => !isActive) || []
        return [...allPersons.value, ...inactivePersons]
    }

    return {
        allPersons,
        isLoadingAllPersons,
        isFetchingAllPersons,
        isRefetchingAllPersons,

        nonExternalPersons,
        isLoadingNonExternalPersons,
        isFetchingNonExternalPersons,
        isRefetchingNonExternalPersons,
        getAllPersonsIncludingInactive,

        allIncludingInactivePersonsForSelector,

        allIncludingInactivePersons,
        isLoadingAllIncludingInactivePersons,
        isFetchingAllIncludingInactivePersons,
        isRefetchingAllIncludingInactivePersons,
    }
}

<script lang="ts" setup>
import { computed, toRef } from 'vue'
import { useSizeMapping, type ExtendedSize } from '@/composables/sizing'

const props = withDefaults(
    defineProps<{
        icon: string
        size?: ExtendedSize
        exactSize?: number
        stroke?: number
    }>(),
    {
        size: 'md',
    }
)

const size = toRef(() => props.exactSize ?? props.size)
const { px: sizePx, rem: sizeRem } = useSizeMapping(size)

const strokeWidth = computed(() => {
    if (props.stroke) return props.stroke

    if (sizePx.value < 16) return 1.5
    if (sizePx.value > 20) return 3
    return 2
})
</script>

<template>
    <div class="inline-block">
        <component :is="`icon-${icon}`" :data-icon="icon" :size="sizeRem" :stroke-width="strokeWidth" />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, useTemplateRef, type ComponentPublicInstance } from 'vue'
import DLabel from './DLabel.vue'
import type { InputTextPassThroughOptions } from 'primevue/inputtext'
import type { PassThrough } from '@primevue/core'
import DIcon from './DIcon.vue'
import type { Size } from '@/composables/sizing'
import type InputText from 'primevue/inputtext'

export interface InputTextProps {
    id?: string
    modelValue?: string | null
    label?: string
    placeholder?: string
    error?: string | boolean
    testid?: string
    helpText?: string
    size?: Size
    ghost?: boolean
    disabled?: boolean
    iconLeft?: string
    focusOnMounted?: boolean
    blurOnEnter?: boolean
    optional?: boolean
    lightTextColor?: boolean
    mediumText?: boolean
    cursorPointer?: boolean
}

const props = withDefaults(defineProps<InputTextProps>(), {
    size: 'md',
    ghost: false,
    disabled: false,
    focusOnMounted: false,
})

defineEmits<{
    (name: 'focusout'): void
    (name: 'keyup.enter'): void
    (name: 'update:model-value', value: string): void
}>()

defineExpose({
    focus() {
        focusInput()
    },
    blur() {
        blurInput()
    },
})

onMounted(() => {
    if (props.focusOnMounted) {
        focusInput()
    }
})

const inputText = useTemplateRef<typeof InputText & ComponentPublicInstance>('inputText')
const inputPT = computed<PassThrough<InputTextPassThroughOptions>>(() => ({
    root: {
        class: [
            'flex',
            'm-0 p-0 w-full',
            'font-sans transition-colors duration-200 appearance-none rounded',
            // Overflow
            'text-ellipsis overflow-hidden whitespace-nowrap',
            // States
            {
                'focus:outline-none border focus:border-primarySky-300': !props.disabled && !props.ghost,
                'focus:outline-none': !props.disabled && props.ghost,
                'select-none border bg-slate-100 border-slate-300 text-slate-400 pointer-events-none cursor-default':
                    props.disabled,
                'bg-rose-50 py-0.5': !props.disabled && props.error && props.ghost,
                'border-rose-500': props.error && !props.ghost,
                'border-slate-300': !props.error && !props.ghost,
                'bg-transparent': props.ghost && !props.error,
                'cursor-pointer': props.cursorPointer && !props.disabled,
            },
            // Text Color
            {
                'text-slate-700': !props.disabled && !props.lightTextColor,
                'text-slate-500': props.lightTextColor,
                'placeholder-slate-400': props.placeholder,
                'font-normal': props.size === 'sm' || props.size === 'md',
                '!font-medium': props.size === 'lg' || props.mediumText,
            },
            // Fonts & Sizing
            {
                'text-sm leading-6 h-6': props.size === 'sm',
                'text-base leading-7': props.size === 'md',
                'text-base leading-8': props.size === 'lg',
                'px-2': !props.ghost,
            },
        ],
    },
}))

const paddingLeft = computed(() => {
    const sizeToPaddingMap = {
        sm: 'pl-[18px]',
        md: 'pl-[22px]',
        lg: 'pl-[26px]',
    }
    return props.iconLeft ? sizeToPaddingMap[props.size] : ''
})

function focusInput() {
    inputText.value?.$el.focus()
}
function blurInput() {
    inputText.value?.$el.blur()
}
</script>

<template>
    <div class="flex flex-col">
        <DLabel
            v-if="label || helpText"
            :label="label"
            :help-text="helpText"
            padding-bottom
            :optional="optional"
            :size="size === 'sm' || size === 'md' ? 'md' : 'lg'"
        />
        <div class="relative flex w-full">
            <DIcon
                v-if="iconLeft"
                class="absolute left-1 top-1/2 -translate-y-1/2 text-slate-400"
                :icon="iconLeft"
                :size="size"
                :stroke="size === 'sm' ? 2 : undefined"
            />
            <PInputText
                :id="id"
                ref="inputText"
                :value="modelValue"
                :placeholder="placeholder"
                :data-testid="testid"
                :aria-describedby="`${id}-help`"
                :disabled="disabled"
                unstyled
                :pt="inputPT"
                :class="[paddingLeft]"
                @update:model-value="$emit('update:model-value', $event as string)"
                @focusout="$emit('focusout')"
                @keydown.enter="($emit('keyup.enter'), blurOnEnter && blurInput())"
            />
        </div>
        <div v-if="error && typeof error === 'string'" class="mt-1 text-xs font-normal text-rose-500">{{ error }}</div>
    </div>
</template>

import type { Plugin } from 'vue'
import { type ToastServiceMethods } from 'primevue/toastservice'
import type { Router } from 'vue-router'
import { MutationCache, QueryCache, QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import { getQueryOnErrorHandler, getMutationOnErrorHandler } from '../utils/vueQueryGlobalErrorHandling'

interface VueQueryPluginOptions {
    router: Router
    toast: ToastServiceMethods
}

const vueQueryPlugin: Plugin<VueQueryPluginOptions> = {
    install: (app, options) => {
        const { router, toast } = options

        app.use(VueQueryPlugin, {
            queryClient: new QueryClient({
                defaultOptions: {
                    queries: {
                        retry: false,
                    },
                    mutations: {
                        retry: false,
                    },
                },
                queryCache: new QueryCache({
                    onError: getQueryOnErrorHandler(router, toast),
                }),
                mutationCache: new MutationCache({
                    onError: getMutationOnErrorHandler(router, toast),
                }),
            }),
        })
    },
}

export default vueQueryPlugin

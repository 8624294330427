import type { App } from 'vue'
import PrimeVue, { type PrimeVueConfiguration } from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

import Select from 'primevue/select'
import Button from 'primevue/button'
import Drawer from 'primevue/drawer'
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'
import TabPanel from 'primevue/tabpanel'
import Menu from 'primevue/menu'
import TieredMenu from 'primevue/tieredmenu'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Chip from 'primevue/chip'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Checkbox from 'primevue/checkbox'
import Listbox from 'primevue/listbox'
import ProgressSpinner from 'primevue/progressspinner'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import TextArea from 'primevue/textarea'
import AutoComplete from 'primevue/autocomplete'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Slider from 'primevue/slider'
import Message from 'primevue/message'
import ToggleSwitch from 'primevue/toggleswitch'
import MultiSelect from 'primevue/multiselect'
import ProgressBar from 'primevue/progressbar'
import Accordion from 'primevue/accordion'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'
import Dialog from 'primevue/dialog'
import Tree from 'primevue/tree'
import TreeSelect from 'primevue/treeselect'
import Skeleton from 'primevue/skeleton'
import Timeline from 'primevue/timeline'
import SelectButton from 'primevue/selectbutton'
import Tag from 'primevue/tag'
import Popover from 'primevue/popover'
import Datepicker from 'primevue/datepicker'
import RadioButton from 'primevue/radiobutton'
import Panel from 'primevue/panel'
import Divider from 'primevue/divider'
import Tooltip from '@/components/DTooltipDirective'

const MyPreset = definePreset(Aura, {
    components: {
        // note: every component that uses inputText as a trigger is affected by this change. Tho, we use unstyled mode for the
        // rest, so in reality it only changes datePicker
        inputtext: {
            paddingX: '0.25rem',
            paddingY: '0rem',
            borderRadius: '0.25rem',
            disabledBackground: '{slate.100}',
            disabledColor: '{slate.400}',
            background: '#FFFFFF00', //transparent
            shadow: '',
        },
        // note: for some reason DT set directly on PDatepicker doesn't work.
        datepicker: {
            panel: {
                padding: '7px',
            },
            header: {
                padding: '0 0 0 0',
            },
            selectMonth: {
                color: '{slate.700}',
            },
            selectYear: {
                color: '{slate.700}',
            },
            weekDay: {
                padding: '0rem',
                fontWeight: '500',
                color: '{slate.700}',
            },
            date: {
                selectedBackground: '{slate.200}',
                selectedColor: '{sky.500}',
                width: '1.5rem',
                height: '1.5rem',
                borderRadius: '6px',
                padding: '4px',
            },
            colorScheme: {
                light: {
                    today: {
                        background: '',
                        color: '{sky.500}',
                    },
                },
            },
        },
        popover: {
            root: {
                gutter: '2px',
            },
            content: {
                padding: 0,
            },
        },
    },
})

export const primevueConfig: PrimeVueConfiguration = {
    theme: {
        preset: MyPreset,
        options: {
            darkModeSelector: false,
        },
    },
    pt: {
        popover: {
            root: {
                class: ['before:!content-none', 'after:!content-none'],
            },
        },
    },
}

export default {
    install(app: App) {
        app.use(PrimeVue, primevueConfig)
        app.use(ToastService)
        app.use(ConfirmationService)
        app.directive('tooltip', Tooltip)
        app.component('PButton', Button)
        app.component('PSelect', Select)
        app.component('PDrawer', Drawer)
        app.component('PSlider', Slider)
        app.component('PMenu', Menu)
        app.component('PTieredMenu', TieredMenu)
        app.component('PDataTable', DataTable)
        app.component('PColumn', Column)
        app.component('PChip', Chip)
        app.component('PInputText', InputText)
        app.component('PInputNumber', InputNumber)
        app.component('PCheckbox', Checkbox)
        app.component('PPanel', Panel)
        app.component('PListbox', Listbox)
        app.component('PProgressSpinner', ProgressSpinner)
        app.component('PToast', Toast)
        app.component('PTextArea', TextArea)
        app.component('PAutoComplete', AutoComplete)
        app.component('PAvatar', Avatar)
        app.component('PAvatarGroup', AvatarGroup)
        app.component('PMessage', Message)
        app.component('PToggleSwitch', ToggleSwitch)
        app.component('PMultiSelect', MultiSelect)
        app.component('PProgressBar', ProgressBar)
        app.component('PAccordion', Accordion)
        app.component('PConfirmDialog', ConfirmDialog)
        app.component('PDialog', Dialog)
        app.component('PTree', Tree)
        app.component('PPopover', Popover)
        app.component('PTreeSelect', TreeSelect)
        app.component('PSkeleton', Skeleton)
        app.component('PTimeline', Timeline)
        app.component('PSelectButton', SelectButton)
        app.component('PTag', Tag)
        app.component('PDatepicker', Datepicker)
        app.component('PRadioButton', RadioButton)
        app.component('PTabs', Tabs)
        app.component('PTabList', TabList)
        app.component('PTab', Tab)
        app.component('PTabPanels', TabPanels)
        app.component('PTabPanel', TabPanel)
        app.component('PDivider', Divider)
    },
}

declare module '@vue/runtime-core' {
    export interface GlobalComponents {
        PButton: typeof Button
        PSelect: typeof Select
        PDrawer: typeof Drawer
        PSlider: typeof Slider
        PMenu: typeof Menu
        PTieredMenu: typeof TieredMenu
        PDataTable: typeof DataTable
        PColumn: typeof Column
        PChip: typeof Chip
        PInputText: typeof InputText
        PInputNumber: typeof InputNumber
        PCheckbox: typeof Checkbox
        PPopover: typeof Popover
        PPanel: typeof Panel
        PListbox: typeof Listbox
        PProgressSpinner: typeof ProgressSpinner
        PToast: typeof Toast
        PTextArea: typeof TextArea
        PAutoComplete: typeof AutoComplete
        PAvatar: typeof Avatar
        PAvatarGroup: typeof AvatarGroup
        PMessage: typeof Message
        PToggleSwitch: typeof ToggleSwitch
        PMultiSelect: typeof MultiSelect
        PProgressBar: typeof ProgressBar
        PAccordion: typeof Accordion
        PConfirmDialog: typeof ConfirmDialog
        PDialog: typeof Dialog
        PTree: typeof Tree
        PTreeSelect: typeof TreeSelect
        PSkeleton: typeof Skeleton
        PTimeline: typeof Timeline
        PSelectButton: typeof SelectButton
        PTag: typeof Tag
        PDatepicker: typeof Datepicker
        PRadioButton: typeof RadioButton
        PTabs: typeof Tabs
        PTabList: typeof TabList
        PTab: typeof Tab
        PTabPanels: typeof TabPanels
        PTabPanel: typeof TabPanel
        PDivider: typeof Divider
    }
}

<script lang="ts" setup>
import { ref, toRef, useTemplateRef } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { BubbleMenu, Editor } from '@tiptap/vue-3'
import DButton from './DButton.vue'
import DInputText from './DInputText.vue'

const props = defineProps<{
    editor?: Editor
}>()

const editor = toRef(props, 'editor')

const inEditLinkMode = ref(false)
const linkUrl = ref<string | null>(null)
const menu = useTemplateRef('bubble-menu')

onClickOutside(menu, leaveLinkEditor)

function setLink() {
    if (!editor.value) return
    const url = linkUrl.value

    if (!url) {
        unsetLink()
    } else {
        editor.value.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
    }
    leaveLinkEditor()
}

function unsetLink() {
    if (!editor.value) return
    editor.value.chain().focus().extendMarkRange('link').unsetLink().run()
    leaveLinkEditor()
}

function openLinkEditor() {
    if (!editor.value) return
    inEditLinkMode.value = true
    linkUrl.value = editor.value.getAttributes('link').href
}

function leaveLinkEditor() {
    linkUrl.value = null
    inEditLinkMode.value = false
}

function followLink() {
    if (!editor.value) return
    const url = editor.value.getAttributes('link').href
    if (url) window.open(url, '_blank')
}
</script>
<template>
    <BubbleMenu
        v-if="editor"
        ref="bubble-menu"
        class="bg-white shadow-sm grid grid-flow-col divide-x p-1 border rounded"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
    >
        <div v-if="!inEditLinkMode" class="flex p-1 space-x-1">
            <DButton
                type="ghost"
                :active="editor.isActive('heading', { level: 1 })"
                icon="h-1"
                @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            />
            <DButton
                type="ghost"
                :active="editor.isActive('heading', { level: 2 })"
                icon="h-2"
                @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            />
        </div>

        <div v-if="!inEditLinkMode" class="flex p-1 space-x-1">
            <DButton
                type="ghost"
                :active="editor.isActive('bold')"
                icon="bold"
                @click="editor.chain().focus().toggleBold().run()"
            />
            <DButton
                type="ghost"
                :active="editor.isActive('italic')"
                icon="italic"
                @click="editor.chain().focus().toggleItalic().run()"
            />
            <DButton
                type="ghost"
                :active="editor.isActive('underline')"
                icon="underline"
                @click="editor.chain().focus().toggleUnderline().run()"
            />
            <DButton
                type="ghost"
                :active="editor.isActive('strike')"
                icon="strikethrough"
                @click="editor.chain().focus().toggleStrike().run()"
            />
        </div>

        <div class="flex items-center p-1">
            <DButton
                v-if="!inEditLinkMode"
                type="ghost"
                :active="editor.isActive('link')"
                icon="link"
                @click="openLinkEditor"
            />

            <div v-if="inEditLinkMode" class="flex items-center gap-1">
                <DInputText
                    v-model="linkUrl"
                    ghost
                    size="sm"
                    placeholder="Enter link URL"
                    focus-on-mounted
                    @keyup.enter="setLink"
                />
                <DButton icon="check" type="ghost" @click="setLink" />
                <DButton icon="external-link" type="ghost" @click="followLink" />
                <DButton icon="trash" type="ghost" @click="unsetLink" />
                <DButton icon="x" type="ghost" @click="leaveLinkEditor" />
            </div>
        </div>

        <div v-if="!inEditLinkMode" class="flex p-1 space-x-1">
            <DButton
                type="ghost"
                :active="editor.isActive('bulletList')"
                icon="list"
                @click="editor.chain().focus().toggleBulletList().run()"
            />
            <DButton
                type="ghost"
                :active="editor.isActive('orderedList')"
                icon="list-numbers"
                @click="editor.chain().focus().toggleOrderedList().run()"
            />
            <DButton
                type="ghost"
                :active="editor.isActive('taskList')"
                icon="list-check"
                @click="editor.chain().focus().toggleTaskList().run()"
            />
        </div>
    </BubbleMenu>
</template>

<script lang="ts" setup>
import { watchEffect } from 'vue'
import { Prisma } from '@prisma/client'
import { useEditor, EditorContent, type JSONContent, type Content, type Range, type FocusPosition } from '@tiptap/vue-3'
import DTextEditorBubbleMenu from './DTextEditorBubbleMenu.vue'
import type { Size } from '@/composables/sizing'
import { useExtensions } from './DTextEditor.extensions'

export type { JSONContent } from '@tiptap/vue-3'

const props = withDefaults(
    defineProps<{
        modelValue?: Prisma.JsonValue
        size?: Size
        placeholder?: string
        editable?: boolean
        ghost?: boolean
        class?: string
        dataTestid?: string
    }>(),
    {
        size: 'md',
        editable: true,
        ghost: false,
    }
)

const emit = defineEmits<{
    (name: 'blur', value: JSONContent): void
    (name: 'update:modelValue', value: JSONContent): void
}>()

defineExpose({
    focus: (position?: FocusPosition) => editor.value?.commands.focus(position),
    blur: () => editor.value?.commands.blur(),
    getText: () => editor.value?.getText() || '',
    getContentSize: () => editor.value?.$doc.content.size || 0,
    insertContentAt: (content: Content, position: number | Range) =>
        editor.value?.commands.insertContentAt(position, content),
    setContent: (content: Content) => editor.value?.commands.setContent(content),
    get isEmpty() {
        return editor.value?.isEmpty ?? true
    },
})

const editor = useEditor({
    content: props.modelValue as JSONContent,
    editorProps: {
        attributes: {
            class: props.class || '',
            ['data-Testid']: props.dataTestid || '',
        },
    },
    onUpdate(props) {
        emit('update:modelValue', props.editor.getJSON())
    },
    onBlur(props) {
        const json = props.editor.getJSON()
        emit('blur', json)
    },
    extensions: useExtensions(props.placeholder),
})

watchEffect(() => {
    if (!editor.value) return
    editor.value.setEditable(props.editable)
})
</script>
<template>
    <EditorContent
        :editor="editor"
        class="prose prose-h1:text-2xl prose-h1:font-semibold prose-h2:text-xl prose-h2:font-medium prose-h3:text-lg prose-h3:font-medium"
        :class="{ 'prose-sm': size === 'sm', 'prose-lg': size === 'lg', border: !ghost }"
    />

    <DTextEditorBubbleMenu :editor="editor" />
</template>
<style lang="css">
.tiptap {
    :first-child {
        margin-top: 0;
    }
    :last-child {
        margin-bottom: 0;
    }

    ul,
    ol {
        li p {
            margin: 0;
        }
    }

    ol {
        list-style-type: decimal;
        ol {
            list-style-type: lower-alpha;
            ol {
                list-style-type: lower-roman;
            }
        }
    }

    a {
        @apply text-primarySky-700 no-underline;
        &:hover {
            @apply underline cursor-pointer;
        }
    }

    p.is-editor-empty:first-child::before {
        @apply text-slate-400;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }

    ul[data-type='taskList'] {
        list-style: none;
        margin-left: 0;
        padding: 0;

        li {
            align-items: flex-start;
            display: flex;

            > label {
                flex: 0 0 auto;
                margin-right: 0.5rem;
                user-select: none;
                align-self: baseline;
            }

            > div {
                flex: 1 1 auto;

                p {
                    margin: 0;
                }
            }
        }

        input[type='checkbox'] {
            @apply accent-primarySky-700;
            cursor: pointer;
            width: 1em;
            height: 1em;
            margin-top: 0.3em;
            &:hover {
                @apply accent-primarySky-900;
            }
        }

        ul[data-type='taskList'] {
            margin: 0;
        }
    }

    [data-type='mention'] {
        @apply text-primarySky-700;
    }
}
</style>

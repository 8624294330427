<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

import { layouts } from './index'

import DVersionCheckBanner from '@/components/DVersionCheckBanner.vue'
import { useOrgDefinedTranslations } from '@/composables/orgDefinedTranslations'

const route = useRoute()
const { reloadTranslations } = useOrgDefinedTranslations()

const layout = computed(() => {
    const layout = (route.meta.layout as keyof typeof layouts) || 'Loading'
    if (!layouts[layout]) {
        throw new Error(`Layout ${layout} does not exist`)
    }
    return layouts[layout]
})

const routeProps = computed(() => route.meta.props as object)

// Version banner and translations reload should not be displayed on layouts which
// can be used by unauthenticated users
const isAuthenticatedLayout = computed(
    () => route.meta.layout && !['Login', 'Loading', 'Empty'].includes(route.meta.layout)
)

// Translations have to be reloaded only once but
// it can happen only for authenticated users
const unwatchTranslationsReload = watch(
    isAuthenticatedLayout,
    async (isAuthenticatedLayout) => {
        if (!isAuthenticatedLayout) return
        await reloadTranslations()
        unwatchTranslationsReload()
    },
    { immediate: true }
)
</script>

<template>
    <DVersionCheckBanner v-if="isAuthenticatedLayout" />
    <Component :is="layout" v-bind="routeProps">
        <slot />
    </Component>
</template>

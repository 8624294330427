<script lang="ts" setup>
import { computed } from 'vue'
import type { Size } from '@/composables/sizing'
import DTag from './DTag.vue'

export interface DEntityTypeTagProps {
    entityType: {
        labelSingular: string
        abbreviation: string
        icon: string
    }
    size?: Size
    transparent?: boolean
    type?: 'short' | 'long'
}

const props = withDefaults(defineProps<DEntityTypeTagProps>(), {
    size: 'md',
    type: 'short',
})

const label = computed(() => (props.type === 'short' ? props.entityType.abbreviation : props.entityType.labelSingular))
</script>

<template>
    <DTag
        v-tooltip.bottom="{
            value: type === 'short' ? entityType.labelSingular : undefined,
        }"
        :size="size"
        :label="label"
        border-style="square"
        icon-color="text-slate-500"
        :icon-left="entityType.icon"
        :class="[{ uppercase: type === 'short' }, { 'bg-transparent': transparent }]"
        readonly
    />
</template>

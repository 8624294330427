<script lang="ts" setup>
import { computed } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

import type { Size } from '@/composables/sizing'
import Tag, { type TagPassThroughOptions } from 'primevue/tag'
import DIcon from './DIcon.vue'
import type { PassThrough } from '@primevue/core'

export interface TagProps {
    label?: string | number
    size?: Size
    iconLeft?: string
    iconRight?: string
    borderStyle?: 'rounded' | 'square'
    font?: 'primary' | 'secondary'
    to?: RouteLocationRaw
    readonly?: boolean
    labelColor?: string
    iconColor?: string
    bgColorClass?: string
    bgColor?: string
    hidden?: boolean
    cursorPointer?: boolean
}

const props = withDefaults(defineProps<TagProps>(), {
    size: 'md',
    borderStyle: 'rounded',
    font: 'primary',
    to: '',
    hidden: false,
})

const tagPT = computed<PassThrough<TagPassThroughOptions>>(() => {
    return {
        root: {
            class: [
                'flex gap-1 flex-shrink items-center text-slate-700',
                props.bgColorClass ?? 'bg-slate-100',
                props.to || props.cursorPointer ? 'cursor-pointer' : 'cursor-default',
                {
                    'cursor-pointer hover:brightness-95': !props.readonly,
                },
                {
                    sm: 'text-xs px-1 h-4',
                    md: 'text-sm px-2 h-5',
                    lg: 'text-base px-3 h-7',
                }[props.size],
                {
                    rounded: 'rounded-full',
                    square: 'rounded-sm',
                }[props.borderStyle],
                {
                    primary: 'font-sans',
                    secondary: 'font-secondary',
                }[props.font],
            ],
            style: [{ 'background-color': props.bgColor }],
        },
    }
})
</script>

<template>
    <Tag v-show="hidden === false" unstyled :pt="tagPT">
        <component :is="to ? 'router-link' : 'div'" :to="to" class="flex items-center gap-1 truncate">
            <slot v-if="iconLeft || $slots.before" name="before">
                <DIcon v-if="iconLeft" :icon="iconLeft" :size="size" :class="iconColor" />
            </slot>
            <slot v-if="label !== undefined || $slots.default">
                <div class="truncate" :class="labelColor ?? 'text-slate-700'">
                    {{ label }}
                </div>
            </slot>
            <slot v-if="iconRight || $slots.after" name="after">
                <DIcon v-if="iconRight" :icon="iconRight" :size="size" :class="iconColor" />
            </slot>
        </component>
    </Tag>
</template>

import { toValue, computed, type Ref } from 'vue'
import { PhaseCategorySetEnum, OpportunityPhaseCategoryEnum, ProductPhaseCategoryEnum } from '@prisma/client'
import { client } from '@/plugins/trpc'
import { useQuery, useMutation } from '@/composables/vue-query-with-magic'
import type { RouterOutput } from '@/types'
import { useI18n } from 'vue-i18n'

export interface IconColorData {
    icon: string
    iconColor: string
}

type AvailablePhase = RouterOutput['entity']['phase']['listAvailable'][0]

export const usePhaseIcons = () => {
    const phaseIcons: {
        [PhaseCategorySetEnum.OpportunityPhaseCategoryEnum]: Record<OpportunityPhaseCategoryEnum, IconColorData>
        [PhaseCategorySetEnum.ProductPhaseCategoryEnum]: Record<ProductPhaseCategoryEnum, IconColorData>
    } = {
        [PhaseCategorySetEnum.OpportunityPhaseCategoryEnum]: {
            [OpportunityPhaseCategoryEnum.new]: { icon: 'circle-dashed', iconColor: 'text-indigo-500' },
            [OpportunityPhaseCategoryEnum.unrealized]: { icon: 'circle-half', iconColor: 'text-purple-500' },
            [OpportunityPhaseCategoryEnum.realized]: { icon: 'circle-check', iconColor: 'text-emerald-500' },
            [OpportunityPhaseCategoryEnum.discontinued]: { icon: 'circle-dashed-x', iconColor: 'text-slate-500' },
        },
        [PhaseCategorySetEnum.ProductPhaseCategoryEnum]: {
            [ProductPhaseCategoryEnum.draft]: { icon: 'circle-dashed', iconColor: 'text-indigo-500' },
            [ProductPhaseCategoryEnum.in_progress]: { icon: 'circle-half-2', iconColor: 'text-purple-500' },
            [ProductPhaseCategoryEnum.live]: { icon: 'circle-check', iconColor: 'text-emerald-500' },
            [ProductPhaseCategoryEnum.discontinued]: { icon: 'circle-dashed-x', iconColor: 'text-slate-500' },
        },
    }

    function getPhaseIcon(
        categorySet: PhaseCategorySetEnum,
        category: OpportunityPhaseCategoryEnum | ProductPhaseCategoryEnum
    ): IconColorData {
        // Typescasting  is safe here because we enforce the same rule in the database. If category set
        // equals "OpportunityPhaseCategoryEnum", then category must be value from enum "OpportunityPhaseCategoryEnum".
        // Otherwise it will ProductPhaseCategoryEnum.
        return categorySet === 'OpportunityPhaseCategoryEnum'
            ? phaseIcons.OpportunityPhaseCategoryEnum[category as OpportunityPhaseCategoryEnum]
            : phaseIcons.ProductPhaseCategoryEnum[category as ProductPhaseCategoryEnum]
    }

    return {
        phaseIcons,
        getPhaseIcon,
    }
}

export const usePhaseBarColors = () => {
    const phaseBarColors: Record<OpportunityPhaseCategoryEnum, string> = {
        [OpportunityPhaseCategoryEnum.new]: 'bg-indigo-500',
        [OpportunityPhaseCategoryEnum.unrealized]: 'bg-purple-500',
        [OpportunityPhaseCategoryEnum.realized]: 'bg-emerald-500',
        [OpportunityPhaseCategoryEnum.discontinued]: 'bg-slate-500',
    }

    function getPhaseBarColor(category: OpportunityPhaseCategoryEnum) {
        return phaseBarColors[category as OpportunityPhaseCategoryEnum]
    }

    return { getPhaseBarColor }
}

export const usePhaseCategoryLabels = () => {
    const { t } = useI18n()
    const phaseCategoryLabels: Record<OpportunityPhaseCategoryEnum, string> = {
        [OpportunityPhaseCategoryEnum.new]: t('missionControl.phaseCategories.new'),
        [OpportunityPhaseCategoryEnum.unrealized]: t('missionControl.phaseCategories.unrealized'),
        [OpportunityPhaseCategoryEnum.realized]: t('missionControl.phaseCategories.realized'),
        [OpportunityPhaseCategoryEnum.discontinued]: t('missionControl.phaseCategories.discontinued'),
    }

    function getPhaseCategoryLabel(category: OpportunityPhaseCategoryEnum) {
        return phaseCategoryLabels[category as OpportunityPhaseCategoryEnum]
    }

    return { getPhaseCategoryLabel }
}

export const usePhases = (categorySet: PhaseCategorySetEnum) => {
    const { getPhaseIcon } = usePhaseIcons()
    const { data, isLoading, isFetching, isRefetching } = useQuery({
        queryKey: ['phases', categorySet],
        queryFn: () => client.entity.phase.listAvailable.query({ categorySet }),
        queries: ['Phase'],
        initialData: [],
    })

    const phases = computed<(AvailablePhase & IconColorData)[]>(() =>
        data.value.map((phase) => ({
            ...phase,
            ...getPhaseIcon(phase.categorySet, phase.category),
        }))
    )

    const defaultPhase = computed(() => phases.value[0])

    return {
        phases,
        defaultPhase,
        isLoading,
        isFetchingPhases: isFetching,
        isRefetching,
        checkIsValidPhase: (phaseId?: string) => !!phaseId && phases.value.some((phase) => phase.id === phaseId),
    }
}

export const useEntityPhase = (id: Ref<string>, enabled = true) => {
    const { data: phase, isPending: isPendingEntityPhase } = useQuery({
        queryKey: ['phase', id],
        queryFn: () => client.entity.phase.get.query({ entityId: toValue(id) }),
        queries: ['Entity'],
        enabled,
    })

    const { mutateAsync } = useMutation({
        mutationFn: client.entity.phase.set.mutate,
        mutates: ['Entity'],
    })

    return {
        phase,
        setPhase: (phaseId: string) => mutateAsync({ entityId: toValue(id), phaseId }),
        isLoading: isPendingEntityPhase,
    }
}

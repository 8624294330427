<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import DPropertyPanel from '@/components/DPropertyPanel.vue'
import DSideNav from '@/components/DSideNav.vue'
import SkeletonSideNav from '@/components/details-view/SkeletonSideNav.vue'
import MarkerButton from '@/components/MarkerButton.vue'
import { useAuthStore } from '@/stores/authStore'
import { useProfileQueries } from '@/queries/profile'
import { useNotificationsInbox, type NotificationItem } from '@/modules/inbox/queries'
import DHeaderBar from '@/components/DHeaderBar.vue'

const authStore = useAuthStore()
const { get: getCurrentUser } = useProfileQueries()

const { list: listAllNotifications } = useNotificationsInbox()
const { data: notificationsList } = listAllNotifications()
const unreadInboxCount = computed(
    () => notificationsList.value?.filter((item: NotificationItem) => !item.isRead).length
)

const { data: user, isPending: isUserLoading } = getCurrentUser()

const route = useRoute()

const entityId = computed(() => route.params.id?.toString())

const showProps = ref(true)
const showNav = ref(true)
</script>
<template>
    <div id="details-layout" class="flex h-screen w-screen overflow-y-hidden">
        <SkeletonSideNav v-if="!user || isUserLoading" />
        <DSideNav
            v-else-if="showNav"
            class="flex-none"
            :user-id="user.id"
            :user="authStore.mappedUser"
            :unread-inbox-count="unreadInboxCount"
        />
        <div class="min-w-main-column-padded flex flex-1 flex-col justify-start">
            <DHeaderBar
                :show-right-icon="true"
                :show-lineage-icon="true"
                :entity-id="entityId"
                @icon-left-toggle="showNav = !showNav"
                @icon-right-toggle="showProps = !showProps"
            >
                <router-view name="header" />
            </DHeaderBar>
            <div class="stable-scrollbar-gutter flex flex-col items-center overflow-auto px-6">
                <router-view />
            </div>
        </div>
        <DPropertyPanel
            class="h-full shrink-0 grow overflow-auto border-l border-slate-300"
            :show="showProps"
            origin="right"
        >
            <router-view name="drawer" />
        </DPropertyPanel>
        <MarkerButton v-if="!showNav" />
    </div>
</template>

import { useI18n } from 'vue-i18n'
import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'

export const useOrgDefinedTranslations = () => {
    const i18n = useI18n()
    const staticMessages = i18n.getLocaleMessage('en')

    const { data: orgDefined, refetch } = useQuery({
        queryKey: ['orgDefinedTranslations'],
        queryFn: () => client.organization.getTranslations.query(),
        queries: [],
        enabled: false,
    })

    async function reloadTranslations() {
        await refetch()

        if (!orgDefined.value) throw new Error('Organization defined translations not found')

        i18n.setLocaleMessage('en', {
            ...staticMessages,
            orgDefined: orgDefined.value,
        })
    }

    return {
        reloadTranslations,
    }
}

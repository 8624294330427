import { client } from '@/plugins/trpc'

import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import HardBreak from '@tiptap/extension-hard-break'
import Mention from '@tiptap/extension-mention'
import { VueRenderer } from '@tiptap/vue-3'
import { PluginKey } from '@tiptap/pm/state'
import MentionPersonList from './DTextEditorMentionPerson.vue'
import MentionEntityList from './DTextEditorMentionEntity.vue'
import tippy, { type Instance, type Props } from 'tippy.js'

export function useExtensions(placeholder?: string) {
    return [
        StarterKit.configure({
            heading: {
                levels: [1, 2, 3],
            },
            hardBreak: false,
        }),
        HardBreak.extend({
            addKeyboardShortcuts() {
                return {
                    'Mod-Enter': () => false,
                }
            },
        }),
        Link.configure({
            openOnClick: true,
            defaultProtocol: 'https',
        }),
        TaskList,
        TaskItem.configure({
            nested: true,
        }),
        Underline,
        Placeholder.configure({
            placeholder: placeholder,
        }),
        Mention.configure({
            HTMLAttributes: {
                class: 'mention',
            },
            suggestion: {
                items: async ({ query }) => {
                    const result = await client.mention.searchUsers.query({ query })
                    return result
                },

                render: () => {
                    let component: VueRenderer
                    let popup: Instance<Props>[]

                    return {
                        onStart: (props) => {
                            component = new VueRenderer(MentionPersonList, {
                                props,
                                editor: props.editor,
                            })

                            if (!props.clientRect) {
                                return
                            }

                            // @ts-expect-error tippy types are not up to date
                            // tippy is in archive mode - we need to migrate to something else
                            // TODO: ONE-1755
                            popup = tippy('body', {
                                getReferenceClientRect: props.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: 'manual',
                                placement: 'bottom-start',
                            })
                        },

                        onUpdate(props) {
                            component.updateProps(props)

                            if (!props.clientRect) {
                                return
                            }

                            popup[0].setProps({
                                getReferenceClientRect: () => (props.clientRect && props.clientRect()) || new DOMRect(),
                            })
                        },

                        onKeyDown(props) {
                            if (props.event.key === 'Escape') {
                                popup[0].hide()

                                return true
                            }

                            return component.ref?.onKeyDown(props)
                        },

                        onExit() {
                            popup[0].destroy()
                            component.destroy()
                        },
                    }
                },
            },
        }),
        Mention.extend({
            name: 'mentionEntity',
        }).configure({
            HTMLAttributes: {
                class: 'mention-entity',
            },
            renderHTML({ options, node }) {
                return [
                    'a',
                    {
                        class: 'mention-entity',
                        'data-id': node.attrs.id,
                        href: `/entity/${node.attrs.id}`,
                    },
                    `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
                ]
            },
            suggestion: {
                char: '#',
                pluginKey: new PluginKey('mentionEntity'),
                items: async ({ query }) => {
                    const result = await client.mention.searchFuzzy.query({ query })
                    console.log(result)
                    return result
                },

                render: () => {
                    let component: VueRenderer
                    let popup: Instance<Props>[]

                    return {
                        onStart: (props) => {
                            component = new VueRenderer(MentionEntityList, {
                                props,
                                editor: props.editor,
                            })

                            if (!props.clientRect) {
                                return
                            }

                            // @ts-expect-error tippy types are not up to date
                            // tippy is in archive mode - we need to migrate to something else
                            // TODO: ONE-1755
                            popup = tippy('body', {
                                getReferenceClientRect: props.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: 'manual',
                                placement: 'bottom-start',
                            })
                        },

                        onUpdate(props) {
                            component.updateProps(props)

                            if (!props.clientRect) {
                                return
                            }

                            popup[0].setProps({
                                getReferenceClientRect: () => (props.clientRect && props.clientRect()) || new DOMRect(),
                            })
                        },

                        onKeyDown(props) {
                            if (props.event.key === 'Escape') {
                                popup[0].hide()

                                return true
                            }

                            return component.ref?.onKeyDown(props)
                        },

                        onExit() {
                            popup[0].destroy()
                            component.destroy()
                        },
                    }
                },
            },
        }),
    ]
}

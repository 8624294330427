import { isDefined } from '@/utils/isDefined'

export const userLocale = navigator.language || navigator.languages[0] || 'de-DE'

export const useNumberFormat = () => {
    function formatNumberToLocale(value: number | null | undefined, options?: Intl.NumberFormatOptions) {
        if (!isDefined(value)) return
        return value.toLocaleString(userLocale, options)
    }

    return {
        formatNumberToLocale,
        locale: userLocale,
    }
}

export const useMetricValueFormatter = (value?: number | null) => {
    if (!isDefined(value)) return
    const absValue = Math.abs(value)

    if (absValue < 10) {
        return `${absValue}`
    }

    const oneThousand = 1000
    if (absValue < oneThousand) {
        return `${value.toString()}`
    }
    if (absValue < oneThousand * 100) {
        return `${(value / oneThousand).toFixed(1)}K`
    }

    const oneMillion = oneThousand * oneThousand
    if (absValue < oneMillion) {
        return `${(value / oneThousand).toFixed(0)}K`
    }
    if (absValue < oneMillion * 100) {
        return `${(value / oneMillion).toFixed(1)}M`
    }

    const oneBillion = oneMillion * oneThousand
    if (absValue < oneBillion) {
        return `${(value / oneMillion).toFixed(0)}M`
    }
    if (absValue < oneBillion * 100) {
        return `${(value / oneBillion).toFixed(1)}B`
    }

    const oneTrillion = oneBillion * oneThousand
    if (absValue < oneTrillion) {
        return `${(value / oneBillion).toFixed(0)}B`
    }
    if (absValue < oneTrillion * 100) {
        return `${(value / oneTrillion).toFixed(1)}T`
    }

    const oneQuadrillion = oneTrillion * oneThousand
    if (absValue < oneQuadrillion) {
        return `${(value / oneTrillion).toFixed(0)}T`
    } else {
        return `${(value / oneTrillion).toFixed(1)}T`
    }
}

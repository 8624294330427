import { computed, type Ref } from 'vue'

export const ExtendedSize = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    '2xl': '2xl',
    '3xl': '3xl',
} as const
export type ExtendedSize = keyof typeof ExtendedSize

export type Size = Extract<ExtendedSize, 'sm' | 'md' | 'lg'>

const BASE_SIZE_PX = 16

const sizePxMapping: Record<ExtendedSize, number> = {
    [ExtendedSize.xs]: 10,
    [ExtendedSize.sm]: 12,
    [ExtendedSize.md]: 16,
    [ExtendedSize.lg]: 20,
    [ExtendedSize.xl]: 24,
    [ExtendedSize['2xl']]: 40,
    [ExtendedSize['3xl']]: 100,
}

export function useSizeMapping(size: Ref<ExtendedSize | number>) {
    const px = computed(() => {
        if (typeof size.value === 'number') {
            return size.value
        }
        return sizePxMapping[size.value]
    })
    const rem = computed(() => px.value / BASE_SIZE_PX + 'rem')

    return { px, rem }
}

<script lang="ts" setup>
import { ref, computed, useTemplateRef } from 'vue'
import type { Person, Entity } from '@prisma/client'
import { v4 as uuidv4 } from 'uuid'
import { type SelectorItem } from '@/composables/selector'

import DAvatar from '@/components/DAvatar.vue'
import DButton, { type DButtonType } from '@/components/DButton.vue'
import DTooltip from '@/components/DTooltip.vue'
import DSelectorGeneric from '@/components/DSelectorGeneric.vue'
import DCommsMenu from '@/components/DCommsMenu.vue'

const props = defineProps<{
    modelValue?: string[]
    people?: Pick<Person, 'id' | 'auth0Id' | 'name' | 'photoUrl' | 'email' | 'isActive'>[]
    placeholder?: string
    filterPlaceholder?: string
    buttonType?: DButtonType
    showTooltip?: boolean
    withCommsMenu?: boolean
    entity?: Pick<Entity, 'label' | 'id'> | undefined
}>()

const peopleSelectorId = `peopleSelector-${uuidv4()}`
const maxNumberOfPeopleInTooltip = 10

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string[]): void
    (name: 'select', value: string): void
    (name: 'unselect', value: string): void
}>()

const peopleTooltip = useTemplateRef('peopleTooltip')
const isSelectorOpened = ref(false)

const options = computed(() => {
    const mappedItems =
        props.people?.map((person) => ({
            id: person.id,
            auth0Id: person.auth0Id,
            name: person.name,
            label: person.name,
            email: person.email,
            photoUrl: person.photoUrl || '',
            isActive: person.isActive,
        })) ?? []
    return mappedItems
})

const selectedPeople = computed(() => options.value.filter(({ id }) => props.modelValue?.includes(id)))

const optionsSelectedAndActiveOnly = computed(() => {
    const activeOnly = options.value.filter((person) => person.isActive)
    const inactiveSelected = selectedPeople.value.filter((person) => !person.isActive)

    return [...activeOnly, ...inactiveSelected]
})

function onModelValueUpdate(items: SelectorItem[]) {
    const modelValue = items.map((item) => item.id)
    emit('update:modelValue', modelValue)
}

const shouldAddCommsMenu = computed(() => {
    return props.withCommsMenu && selectedPeople.value?.length
})
</script>

<template>
    <div :class="{ 'flex justify-between items-center overflow-hidden': shouldAddCommsMenu }">
        <div :class="{ 'flex-1': shouldAddCommsMenu }">
            <DSelectorGeneric
                :model-value="selectedPeople"
                :options="optionsSelectedAndActiveOnly"
                multiple
                max-width="sm"
                :filter-placeholder="filterPlaceholder"
                :no-items-label="$t('components.SelectorItem.noPersonsFound')"
                @show="isSelectorOpened = true"
                @hide="isSelectorOpened = false"
                @select-item="emit('select', $event)"
                @unselect-item="emit('unselect', $event)"
                @update:model-value="onModelValueUpdate"
            >
                <slot>
                    <DButton
                        :id="peopleSelectorId"
                        :type="buttonType ?? 'ghost'"
                        @mouseenter="peopleTooltip?.show"
                        @mouseleave="peopleTooltip?.hide"
                    >
                        <template v-if="selectedPeople?.length">
                            <div class="flex gap-x-1 overflow-hidden">
                                <DAvatar :users="selectedPeople" size="md" />
                            </div>
                        </template>
                        <template v-else>
                            <span class="truncate text-slate-400">{{ placeholder || $t('notAssigned') }}</span>
                        </template>
                    </DButton>

                    <DTooltip
                        v-if="showTooltip && selectedPeople?.length"
                        ref="peopleTooltip"
                        placement="bottom-end"
                        :anchor="peopleSelectorId"
                    >
                        <div class="grid grid-cols-1 gap-2 p-1">
                            <div
                                v-for="person in selectedPeople.slice(0, maxNumberOfPeopleInTooltip)"
                                :key="person?.id"
                                class="flex items-center gap-x-2"
                            >
                                <DAvatar :users="[person]" size="xs" />
                                <span class="truncate">{{ person?.label }}</span>
                            </div>
                        </div>
                        <div class="ml-7">
                            <span v-if="selectedPeople.length > maxNumberOfPeopleInTooltip">
                                +{{ selectedPeople.length - maxNumberOfPeopleInTooltip }}
                            </span>
                        </div>
                    </DTooltip>
                </slot>
            </DSelectorGeneric>
        </div>
        <DCommsMenu v-if="shouldAddCommsMenu && entity" :people="selectedPeople" :entity />
    </div>
</template>

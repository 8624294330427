import { z } from 'zod'
import type { JSONContent } from '@tiptap/core'

const baseContentSchema = z.object({
    type: z.string().optional(),
    attrs: z.record(z.any()).optional(),
    marks: z
        .array(
            z.object({
                type: z.string(),
                attrs: z.record(z.any()).optional(),
            })
        )
        .optional(),
    text: z.string().optional(),
})

export const textContentSchema: z.ZodType<JSONContent> = baseContentSchema.extend({
    content: z.lazy(() => textContentSchema.array().optional()),
})

export const emptyTextContent = { type: 'doc', content: [{ type: 'paragraph' }] }

export function parseMentions(data: JSONContent): string[] {
    const mentions = (data.content || []).flatMap(parseMentions)
    if (isMention(data)) {
        mentions.push(data.attrs.id)
    }

    const uniqueMentions = [...new Set(mentions)]

    return uniqueMentions
}

function isMention(data: JSONContent): data is { type: 'mention'; attrs: { id: string; label: string } } {
    return data.type === 'mention'
}

export function getMentionsDiff(original: JSONContent | null, update: JSONContent) {
    const mentions = parseMentions(update)
    if (!original) return mentions
    const originalMentions = parseMentions(original)
    return mentions.filter((mention) => !originalMentions.includes(mention))
}

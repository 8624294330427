import { CustomPropertyTypeEnum } from '@prisma/client'

export const iconForCustomProperty: { [key in CustomPropertyTypeEnum]: string } = {
    [CustomPropertyTypeEnum.single_select]: 'select-all',
    [CustomPropertyTypeEnum.multi_select]: 'list-check',
    [CustomPropertyTypeEnum.text]: 'text-size',
    [CustomPropertyTypeEnum.checkbox]: 'toggle-left',
    [CustomPropertyTypeEnum.currency]: 'coins',
    [CustomPropertyTypeEnum.number]: 'hash',
    [CustomPropertyTypeEnum.percentage]: 'percentage',
    [CustomPropertyTypeEnum.date_picker]: 'calendar',
    [CustomPropertyTypeEnum.multi_person]: 'user',
}

import { z } from 'zod'
import { useI18n } from 'vue-i18n'
import { OpportunityPriorityEnum, Prisma, ProductPriorityEnum } from '@prisma/client'
import { MAX_LENGTH } from '@mindfuel/server/src/common/config'
import { textContentSchema } from '@mindfuel/server/src/utils/text-content'

export function useSchema() {
    const { t } = useI18n()

    const entity = z.object({
        label: z
            .string()
            .trim()
            .min(1, t('formValidation.required'))
            .min(3, t('formValidation.minChars', [3]))
            .max(MAX_LENGTH.TITLE, t('formValidation.maxChars', [MAX_LENGTH.TITLE])),
        description: textContentSchema.optional(),
        phaseId: z.string(),
        ownerId: z.string().optional().nullable(),
        keywordIds: z.array(z.string()).optional(),
        businessTeamIds: z.array(z.string()).optional(),
        dataTeamIds: z.array(z.string()).optional(),
        customProperties: z.array(z.object({ id: z.string(), valueIds: z.array(z.string()) })).optional(),
        freeformCustomProperty: z
            .object({
                propertyId: z.string(),
                propertyLabel: z.string(),
                valueLabel: z.string(),
                valueText: z.string().optional(),
                valueNumber: z.instanceof(Prisma.Decimal).optional(),
                valueDate: z.date().optional(),
                valueBoolean: z.boolean().optional(),
            })
            .optional(),
        businessGoalIds: z.array(z.string()).optional(),
        metricIds: z.array(z.string()).optional(),
        stakeholderIds: z.array(z.string()).optional(),
    })

    const opportunity = z.object({
        priority: z.nativeEnum(OpportunityPriorityEnum),
    })

    const dataProduct = z.object({
        typeId: z.string(),
        priority: z.nativeEnum(ProductPriorityEnum),
        ideaId: z.string().optional(),
        opportunityIds: z.array(z.string()).optional(),
    })

    return {
        opportunitySchema: entity.merge(opportunity),
        dataProductSchema: entity.merge(dataProduct),
    }
}

<script lang="ts" setup>
import { ref, watch } from 'vue'
import DButton from './DButton.vue'
import DAvatar from './DAvatar.vue'
import type { PersonType } from '@/composables/data/person'

const props = defineProps<{
    items: PersonType[]
    command: (item: { id: string; label: string }) => void
}>()

defineExpose({ onKeyDown })

const selectedIndex = ref(0)
watch(
    () => props.items,
    () => {
        selectedIndex.value = 0
    }
)

function onKeyDown({ event }: { event: KeyboardEvent }) {
    if (event.key === 'ArrowUp') {
        upHandler()
        return true
    }

    if (event.key === 'ArrowDown') {
        downHandler()
        return true
    }

    if (event.key === 'Enter') {
        enterHandler()
        return true
    }

    return false
}

function upHandler() {
    selectedIndex.value = (selectedIndex.value + props.items.length - 1) % props.items.length
}

function downHandler() {
    selectedIndex.value = (selectedIndex.value + 1) % props.items.length
}

function enterHandler() {
    selectItem(selectedIndex.value)
}

function selectItem(index: number) {
    const item = props.items[index]

    if (item) {
        props.command({ id: item.id, label: item.name })
    }
}
</script>

<template>
    <div
        data-testid="person-mention-list"
        class="bg-white rounded-md shadow-elevation-medium flex flex-col py-2 px-1 gap-1"
    >
        <template v-if="items.length">
            <DButton
                v-for="(person, index) in items"
                :key="index"
                type="ghost"
                :active="index === selectedIndex"
                horizontal-alignment="left"
                @click="selectItem(index)"
            >
                <span class="flex items-center gap-1 truncate">
                    <DAvatar :users="[person]" size="xs" />
                    <span class="truncate">{{ person.name }}</span>
                </span>
            </DButton>
        </template>
        <div v-else class="item">{{ $t('noResults') }}</div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { PassThrough } from '@primevue/core'
import type { DialogPassThroughOptions } from 'primevue/dialog'

import DButton from './DButton.vue'
import DDivider from './DDivider.vue'
import DAlertDialog from './DAlertDialog.vue'
import MarkerButton from './MarkerButton.vue'

const { t } = useI18n()

const emit = defineEmits<{
    (name: 'update:is-visible', isVisible: boolean): void
    (name: 'confirm'): void
    (name: 'cancel'): void
}>()
const props = withDefaults(
    defineProps<{
        showPageOverlay?: boolean
        headerTitle?: string
        showClose?: boolean
        hideDivider?: boolean
        isVisible: boolean
        hasChanges?: boolean
        confirmLabel?: string
        cancelLabel?: string
        confirmButtonSubmit?: boolean
        formName?: string
        height?: string
        width?: string
        isConfirmButtonDisabled?: boolean
        isConfirmButtonLoading?: boolean
    }>(),
    {
        showPageOverlay: true,
        showClose: true,
        hideDivider: false,
        confirmLabel: '',
        cancelLabel: '',
        confirmButtonSubmit: false,
        isConfirmButtonDisabled: false,
        isConfirmButtonLoading: false,
    }
)
const computedVisible = computed({
    get() {
        return props.isVisible
    },
    set(value) {
        emit('update:is-visible', value)
    },
})

const modalPT = computed<PassThrough<DialogPassThroughOptions>>(() => ({
    mask: {
        class: '!z-backdrop bg-black/15 backdrop-blur-sm', // overwrite primevue (1101) with our tailwind class
    },
    root: {
        class: [
            'z-modal shadow-elevation-high border-primarySky-100 m-4 -mt-24 rounded-lg border max-h-[70%] bg-white p-4',
            props.width ?? 'w-[720px]',
            props.height,
        ],
    },
    header: { class: 'flex items-center justify-between flex-shrink-0 mb-3 text-heading-2' },
    content: { class: 'mb-3 overflow-y-auto' },
    pcCloseButton: {
        root: {
            class: 'bg-transparent text-slate-400 font-semibold outline-primarySky-300 hover:text-slate-500 flex items-center p-1',
        },
        // Note: hiding &nbsp character added by primevue4, reported issue: https://github.com/primefaces/primevue/issues/7123
        label: { class: 'hidden' },
    },
}))

const cancelDialogVisible = ref(false)
const cancelWithDialog = () => {
    cancelDialogVisible.value = true
}

const confirm = () => {
    emit('confirm')
    computedVisible.value = false
}
const cancel = () => {
    cancelDialogVisible.value = false
    emit('cancel')
    computedVisible.value = false
}
</script>

<template>
    <PDialog
        :data-testid="$attrs['data-testid']"
        :visible="computedVisible"
        unstyled
        modal
        :draggable="false"
        :closable="showClose"
        :pt="modalPT"
        @update:visible="hasChanges ? cancelWithDialog() : cancel()"
    >
        <slot />
        <template #header>
            <slot name="header">
                {{ headerTitle }}
            </slot>
        </template>
        <template #footer>
            <DDivider v-if="!hideDivider" class="mb-5" />
            <slot name="footer">
                <div class="flex gap-1">
                    <slot name="footer-action" />
                    <div class="flex-1 flex-grow" />
                    <DButton :label="cancelLabel || t('cancel')" @click="hasChanges ? cancelWithDialog() : cancel()" />
                    <DButton
                        data-testid="modal-confirm-button"
                        type="confirm"
                        :submit="confirmButtonSubmit"
                        :form="formName"
                        :label="confirmLabel || t('confirm')"
                        :disabled="isConfirmButtonDisabled"
                        :loading="isConfirmButtonLoading"
                        @click="confirmButtonSubmit || confirm()"
                    />
                </div>
            </slot>
        </template>
    </PDialog>
    <DAlertDialog
        v-model:visible="cancelDialogVisible"
        :header="t('dialog.changes.header')"
        :content="t('dialog.changes.message')"
        :confirm-button-label="t('dialog.changes.yes')"
        :cancel-button-label="t('dialog.changes.keepEditing')"
        type="warn"
        modal
        @confirm="cancel"
        @cancel="cancelDialogVisible = false"
    />
    <MarkerButton v-if="computedVisible" />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { CustomPropertyTypeEnum } from '@prisma/client'

import { useSelector, type SelectorItem } from '@/composables/selector'

import DIcon from './DIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'
import DTags from './DTags.vue'

const {
    UNSELECT_ITEM: { id: NOT_SELECTED },
} = useSelector()

const props = withDefaults(
    defineProps<{
        modelValue?: string[]
        customProperty?: {
            values: {
                id: string
                label: string
            }[]
            id: string
            label?: string
            type: CustomPropertyTypeEnum
        }
        filterPlaceholder?: string
        triggerType?: 'button' | 'tags'
        // Relevant only for triggerType: tags
        withShowMore?: boolean
        // Relevant only for triggerType: button
        buttonType?: DButtonType
        testid?: string
    }>(),
    {
        buttonType: 'ghost',
        triggerType: 'button',
        testid: 'd-selector-customProperties',
    }
)

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string[]): void
    (name: 'select', value: string): void
    (name: 'unselect', value: string): void
}>()

const showMore = ref(false)
const selected = computed(() => props.customProperty?.values.filter((item) => props.modelValue?.includes(item.id)))
const showPlaceholder = computed(() => !props.modelValue?.length)

const onModelUpdate = (items: SelectorItem[]) => {
    showMore.value = true
    emit('update:modelValue', items[0]?.id === NOT_SELECTED ? [] : items.map((item) => item.id))
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="customProperty?.values || []"
        max-width="sm"
        :unselect-item-label="$t('notAssigned')"
        :multiple="customProperty?.type === CustomPropertyTypeEnum.multi_select"
        :filter-placeholder="filterPlaceholder"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect', $event)"
        @update:model-value="onModelUpdate"
    >
        <DButton v-if="triggerType === 'button'" :type="buttonType ?? 'ghost'" :data-testid="testid">
            <template v-if="showPlaceholder">
                <DIcon icon="customProperty" class="text-slate-500" size="md" />
                {{ customProperty?.label }}: {{ $t('notSet') }}
            </template>
            <template v-else>
                <DIcon icon="customProperty" size="md" />
                {{
                    selected && selected?.length > 1
                        ? $t('filters.properties.customPropertySelect', { count: selected.length })
                        : selected?.[0]?.label
                }}
            </template>
        </DButton>
        <div v-else class="cursor-pointer" :data-testid="testid">
            <DTags
                v-if="selected?.length"
                v-model:show-more="showMore"
                :tags="selected"
                cursor-pointer
                class="pt-0.5"
                :with-show-more
            />
            <DButton v-else type="ghost">
                <span class="truncate text-slate-400">
                    {{ $t('notAssigned') }}
                </span>
            </DButton>
        </div>
    </DSelectorGeneric>
</template>

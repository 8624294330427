<script lang="ts" setup>
import { computed, toRef } from 'vue'

import AvatarGroup from 'primevue/avatargroup'
import { type AvatarPassThroughOptions } from 'primevue/avatar'
import type { PassThrough } from '@primevue/core'

import DIcon from './DIcon.vue'
import { useSizeMapping, type ExtendedSize } from '@/composables/sizing'

const props = withDefaults(
    defineProps<{
        users: { id?: string; photoUrl?: string | null; isActive?: boolean }[]
        size?: ExtendedSize
        selectedUserId?: string
        max?: number
    }>(),
    {
        size: 'md',
        max: 3,
    }
)

const size = toRef(props, 'size')
const { rem: sizeRem } = useSizeMapping(size)

const visibleUsers = computed(() => props.users.slice(0, props.max))
const hiddenCount = computed(() => props.users.length - props.max)

const sharedRootStyle = computed(() => `height: ${sizeRem.value}; width: ${sizeRem.value}`)
const sharedRootClass = computed(
    () => 'inline-flex justify-center items-center rounded-full border text-slate-400 border-slate-400'
)

const avatarPT = computed<PassThrough<AvatarPassThroughOptions>>(() => ({
    root: {
        style: sharedRootStyle.value,
        class: [sharedRootClass.value, props.users.length > 0 ? 'bg-white' : 'bg-gray-200'],
    },
    image: {
        class: ['rounded-full'],
    },
    icon: {
        class: ['rounded-full'],
    },
}))

const counterPT = computed<PassThrough<AvatarPassThroughOptions>>(() => ({
    root: {
        style: sharedRootStyle.value,
        class: [sharedRootClass.value, 'bg-slate-100'],
    },
    label: {
        class: 'text-[0.5rem]',
    },
    icon: {
        class: ['rounded-full'],
    },
}))
</script>

<template>
    <AvatarGroup unstyled class="flex items-center -space-x-2">
        <template v-for="user in visibleUsers" :key="user.id">
            <!-- deactivated -->
            <PAvatar v-if="user.isActive === false" unstyled :pt="avatarPT" class="border-dashed">
                <DIcon icon="user-x" :size="size" />
            </PAvatar>

            <!-- active -->
            <PAvatar v-else-if="user.photoUrl" unstyled :image="user.photoUrl" :pt="avatarPT" class="border-none" />

            <!-- passive -->
            <PAvatar v-else unstyled :pt="avatarPT">
                <DIcon icon="user-pause" :size="size" />
            </PAvatar>
        </template>

        <PAvatar v-if="hiddenCount > 0" :label="`+${hiddenCount}`" unstyled :pt="counterPT" />

        <PAvatar v-if="!users.length" unstyled :pt="avatarPT" class="border-dashed">
            <DIcon icon="user" :size="size" />
        </PAvatar>
    </AvatarGroup>
</template>
